import { type BaseItem } from '@algolia/autocomplete-core';
import * as AutocompleteQuerySuggestions from '@algolia/autocomplete-plugin-query-suggestions';
import {
  createLocalStorageRecentSearchesPlugin,
  search,
} from '@algolia/autocomplete-plugin-recent-searches';
import { pipe } from 'ramda';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { useRef, useEffect, createElement, Fragment } from 'react';

import { createFillWith, uniqBy } from '#app/utils/algoliasearch.js';

import { type AutocompleteOptions } from '@algolia/autocomplete-js';
import { autocomplete } from '@algolia/autocomplete-js';

import '@algolia/autocomplete-theme-classic';
import { Root, createRoot } from 'react-dom/client';
import { cn } from '#app/utils/misc.js';

type AutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
  className?: string;
  envVars: EnvVarsType;
};

export default function Autocomplete({
  className,
  envVars,
  ...autocompleteProps
}: AutocompleteProps) {
  const autocompleteContainer = useRef<HTMLDivElement>(null);
  const panelRootRef = useRef<Root | null>(null);
  const rootRef = useRef<HTMLElement | null>(null);

  const removeDuplicates = uniqBy(({ source, item }) => {
    const sourceIds = ['recentSearchesPlugin', 'querySuggestionsPlugin'];
    if (sourceIds.indexOf(source.sourceId) === -1) {
      return item;
    }

    return source.sourceId === 'querySuggestionsPlugin'
      ? item.query
      : item.label;
  });

  const { createQuerySuggestionsPlugin } = AutocompleteQuerySuggestions;

  const { ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_KEY, ALGOLIA_PREFIX } = envVars;

  const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_KEY);
  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName: ALGOLIA_PREFIX + 'query_suggestions',
    getSearchParams({ state }) {
      return {
        hitsPerPage: !state.query ? 0 : 10,
      };
    },
    transformSource({ source }) {
      return {
        ...source,
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          ...source.templates,
          header({ Fragment }) {
            return (
              <Fragment>
                <span className="aa-SourceHeaderTitle">Suggestions</span>
                <div className="aa-SourceHeaderLine" />
              </Fragment>
            );
          },
        },
      };
    },
  });
  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: ALGOLIA_PREFIX + 'localStorage',
    search(params) {
      return search({ ...params, limit: params.query ? 1 : 4 });
    },
    transformSource({ source }) {
      return {
        ...source,
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          ...source.templates,
          header({ Fragment }) {
            return (
              <Fragment>
                <span className="aa-SourceHeaderTitle">Recents</span>
                <div className="aa-SourceHeaderLine" />
              </Fragment>
            );
          },
        },
      };
    },
  });

  const fillWith = createFillWith({
    mainSourceId: 'querySuggestionsPlugin',
    limit: 6,
  });

  const combine = pipe(removeDuplicates, fillWith);

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return;
    }

    const autocompleteInstance = autocomplete({
      ...autocompleteProps,
      container: autocompleteContainer.current,
      insights: true,
      plugins: [recentSearchesPlugin, querySuggestionsPlugin],
      reshape({ sourcesBySourceId, state }) {
        const {
          recentSearchesPlugin: recentSearches,
          querySuggestionsPlugin: querySuggestions,
          ...rest
        } = sourcesBySourceId;

        return [
          combine(recentSearches, querySuggestions),
          [...Object.values(rest)].filter(Boolean),
        ];
      },

      onSubmit({ state }) {
        if (state.query) {
          window.location.replace('/search/products?q=' + state.query);
        }
      },
      renderer: { createElement, Fragment, render: () => { } },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
    });

    // fix for multiple instance of autocomplete in one page
    const handleClickOutside = (e: MouseEvent | TouchEvent) => {
      const target = (e.target as HTMLElement);
      const clickInsideAutocomplete = target.closest('.aa-Autocomplete');
      const clickDetachedContainer = target.closest('.aa-DetachedContainer');

      if (!clickInsideAutocomplete && !clickDetachedContainer) {
        autocompleteInstance.setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      autocompleteInstance.destroy();
    };
  }, [autocompleteProps]);

  return (
    <div
      className={cn(['custom-autocomplete lg:min-w-[220px]', className])}
      ref={autocompleteContainer}
    />
  );
}
