import React from 'react';
import { Link } from '@remix-run/react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '#app/components/ui/accordion';
import { Container, ContainerContent } from '#app/components/layouts/container';
import logoDfars from '/img/logos/logo-dfars.svg';
import logoHeico from '/img/logos/logo-heico.svg';
import logoMilPrf from '/img/logos/logo-mil-prf.svg';

function FooterNavDesktop({ data }: { data: NavItemProps }) {
  return (
    <div className="hidden w-full gap-x-[30px] lg:flex">
      {data.nav.map((item) =>
        item.hasSubmenu && item.submenu[0]?.typeHandle === 'navLists' ? (
          <div key={item.id}>
            <Link
              to={`/${item?.submenu[0].headingEntry?.[0]?.uri}`}
              className="mb-5 block font-medium text-white hover:underline hover:underline-offset-4"
            >
              {item.itemName}
            </Link>
            <ul className="flex flex-col text-sm text-neutral-400">
              {item.submenu[0].navLinks.map((navLink, idx) => {
                if (!navLink.hideOnFooterOrMobile) {
                  if (item.itemName === 'Contact' && idx === 0) {
                    return (
                      <React.Fragment key={navLink.id}>
                        <li className="mb-4 text-white">
                          <a
                            href="tel:425-353-3010"
                            target="_blank"
                            className="hover:underline hover:underline-offset-2"
                          >
                            T: 425.353.3010
                          </a>
                        </li>
                        <li className="mb-4">
                          <Link
                            to={`/${navLink.entry?.[0]?.uri}`}
                            className="hover:underline hover:underline-offset-2"
                            prefetch="intent"
                          >
                            {navLink.entry?.[0]?.title}
                          </Link>
                        </li>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <li key={navLink.id} className="mb-4">
                        <Link
                          to={`/${navLink.entry?.[0]?.uri}`}
                          className="hover:underline hover:underline-offset-2"
                          prefetch="intent"
                        >
                          {navLink.entry?.[0]?.title}
                        </Link>
                      </li>
                    );
                  }
                }
              })}
            </ul>
          </div>
        ) : null,
      )}
    </div>
  );
}

function FooterNavMobile({ data }: { data: NavItemProps }) {
  return (
    <Accordion type="single" collapsible className="w-full lg:hidden">
      {data.nav.map((item) =>
        item.hasSubmenu && item.submenu[0]?.typeHandle === 'navLists' ? (
          <div key={item.id} className="[&:last-child>div]:border-b-0">
            <AccordionItem value={item.id}>
              <AccordionTrigger className="text-white">
                {item.itemName}
              </AccordionTrigger>
              <AccordionContent className="flex flex-col">
                {item.itemName === 'Resources' ? (
                  <Link
                    key="all-resources"
                    to="/resources"
                    className="mb-4 text-neutral-400 hover:underline hover:underline-offset-2"
                    prefetch="intent"
                  >
                    All Resources
                  </Link>
                ) : (
                  ''
                )}
                {item.submenu[0].navLinks.map((navLink, idx) => {
                  if (!navLink.hideOnFooterOrMobile) {
                    if (item.itemName === 'Contact' && idx === 0) {
                      return (
                        <React.Fragment key={navLink.id}>
                          <a
                            href="tel:425-353-3010"
                            target="_blank"
                            className="mb-4 text-white hover:underline hover:underline-offset-2"
                          >
                            T: 425.353.3010
                          </a>
                          <Link
                            key={navLink.id}
                            to={`/${navLink.entry?.[0]?.uri}`}
                            className="mb-4 text-neutral-400 hover:underline hover:underline-offset-2"
                            prefetch="intent"
                          >
                            {navLink.entry?.[0]?.title}
                          </Link>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <Link
                          key={navLink.id}
                          to={`/${navLink.entry?.[0]?.uri}`}
                          className="mb-4 text-neutral-400 hover:underline hover:underline-offset-2"
                          prefetch="intent"
                        >
                          {navLink.entry?.[0]?.title}
                        </Link>
                      );
                    }
                  }
                })}
              </AccordionContent>
            </AccordionItem>
          </div>
        ) : null,
      )}
    </Accordion>
  );
}

function Footer({ data }: { data: NavItemProps }) {
  return (
    <Container className="relative z-30 bg-gray pb-5 pt-8 lg:min-h-[315px]">
      <ContainerContent className="flex h-full flex-col justify-between">
        <div className="flex flex-col items-center justify-between gap-x-12 px-[15px] md:px-[30px] lg:flex-row lg:px-[15px] lg:pb-6">
          <FooterNavDesktop data={data} />
          <FooterNavMobile data={data} />

          <div className="mt-3 w-full border-b border-neutral-600 lg:mt-0 lg:hidden lg:border-b-0" />
          <div className="my-[30px] flex justify-center lg:border-l lg:border-neutral-600 lg:pl-12">
            <div className="flex w-full flex-row gap-x-7 lg:w-[96px] lg:flex-col lg:gap-y-[23px]">
              <img src={logoDfars} width={66} height={66} alt="DFARS logo" loading="lazy" />
              <img src={logoHeico} width={96} height={66} alt="Heico logo" loading="lazy" />
              <img src={logoMilPrf} width={98} height={66} alt="MIL-PRF logo" loading="lazy" />
            </div>
          </div>
        </div>

        <div className="h-8x flex flex-col items-center justify-between px-[15px] text-xs tracking-[0.16px] text-neutral-400 lg:flex-row">
          <p className="mb-[30px] text-center lg:mb-0 lg:text-left">
            Copyright © VPT, Inc. {new Date().getFullYear()}. All Rights
            Reserved. VPT, its logo, and tagline are all trademarks and service
            marks owned by VPT, Inc.
          </p>
          <div className="">
            <Link
              to="/privacy-policy"
              className="underline underline-offset-2 hover:no-underline"
              prefetch="intent"
            >
              Privacy
            </Link>{' '}
            |{' '}
            <Link
              to="/terms-and-conditions"
              className="underline underline-offset-2 hover:no-underline"
              prefetch="intent"
            >
              Terms & Conditions
            </Link>{' '}
            |{' '}
            <Link
              to="/cookie-policy"
              className="underline underline-offset-2 hover:no-underline"
              prefetch="intent"
            >
              Cookie Policy
            </Link>
          </div>
        </div>
      </ContainerContent>
    </Container>
  );
}

export default Footer;
