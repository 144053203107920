import React from 'react';
import { Link, useLocation, useNavigate } from '@remix-run/react';
import { Image } from '@unpic/react';

// import SearchBox from '#app/components/search-box';
import TopBarMobile from './top-bar-mobile';
import VPTLogo from '#app/assets/img/vpt-logo-blue.svg';
import { cn } from '#app/utils/misc.js';
import { Grid, Frame } from '#app/components/layouts';
import {
  MotionBar,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuContentLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  SiteWideMessageBar,
  BottomBorderClip,
  useNav,
} from '#app/components/ui/top-bar-navigation-menu';
import Autocomplete from '#app/components/algolia/autocomplete.js';

function useActiveTrigger() {
  const { pathname } = useLocation();
  const firstSegment = pathname.split('/').filter(Boolean)[0];

  let activeTrigger = '';

  switch (firstSegment) {
    case 'contact-us':
      activeTrigger = 'Contact';
      break;
    case 'company':
    case 'newsroom':
      activeTrigger = 'Company';
      break;
    case 'applications':
      activeTrigger = 'Applications';
      break;
    case 'quality':
      activeTrigger = 'Quality';
      break;
    case 'resources':
      activeTrigger = 'Resources';
      break;
    case 'products':
      activeTrigger = 'Products';
      break;
  }

  return activeTrigger;
}

function PageAndListsSubmenu({ item }: { item: NavItem }) {
  const activeTrigger = useActiveTrigger();
  const navigate = useNavigate();
  const gridColsStyles =
    item.itemName === 'Company'
      ? 'grid-cols-[repeat(5,_auto)]'
      : 'grid-cols-[repeat(4,_auto)]';

  return item.hasSubmenu && item.submenu[0]?.typeHandle === 'pageAndLists' ? (
    <NavigationMenuItem>
      <NavigationMenuTrigger
        className={
          activeTrigger === item.itemName
            ? 'border-b-4 border-primary data-[state=open]:border-primary'
            : ''
        }
        onClick={() =>
          navigate(
            item.hasSubmenu && item.submenu[0]?.typeHandle === 'pageAndLists'
              ? `/${item.submenu?.[0]?.entry?.[0]?.uri}`
              : '#',
          )
        }
      >
        {/* <div className="absolute -bottom-1 left-0 w-[15px] border-b-4 border-white" /> */}
        {item.itemName}
      </NavigationMenuTrigger>
      <NavigationMenuContent className="absolute">
        <div className={cn('min-h-[200px] w-screen px-10 pb-[30px] pt-5')}>
          <Grid
            className={cn(
              'inline-grid',
              'gap-x-[50px]',
              'relative left-[calc(calc((100vw_-_1140px)/2)_+_187px)]',
              gridColsStyles,
            )}
          >
            <NavigationMenuContentLink
              asChild
              className="text-lg font-medium text-primary hover:underline hover:underline-offset-2"
            >
              <Link
                prefetch="intent"
                to={`/${item.submenu?.[0]?.entry?.[0]?.uri}`}
              >
                {item.submenu[0].entry && item.submenu[0].navTitle
                  ? item.submenu[0].navTitle
                  : item.submenu[0].entry?.[0]?.title}
              </Link>
            </NavigationMenuContentLink>

            {item.submenu[0].listOfLinks.map((listLink) => (
              <div key={listLink.id} className="mt-1">
                {listLink.heading ? (
                  <h4 className="mb-1 text-xs leading-6 text-neutral-420">
                    {listLink.heading}
                  </h4>
                ) : (
                  <h4 className="h-[30px]" />
                )}

                <ul>
                  {listLink.navLinks.map((navLink) => (
                    <li key={navLink.id}>
                      <NavigationMenuContentLink
                        asChild
                        className="text-sm leading-7 text-gray hover:underline hover:underline-offset-2"
                      >
                        <Link
                          prefetch="intent"
                          to={`/${navLink.entry?.[0]?.uri}`}
                        >
                          {navLink.entry?.[0]?.slug === 'newsroom'
                            ? 'All Content'
                            : navLink.entry?.[0]?.title}
                        </Link>
                      </NavigationMenuContentLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Grid>
        </div>
      </NavigationMenuContent>
    </NavigationMenuItem>
  ) : null;
}

function NavListsSubmenu({ item }: { item: NavItem }) {
  const activeTrigger = useActiveTrigger();
  const navigate = useNavigate();

  return item.hasSubmenu && item.submenu[0]?.typeHandle === 'navLists' ? (
    <NavigationMenuItem>
      <NavigationMenuTrigger
        onClick={() => navigate('/applications')}
        className={
          activeTrigger === item.itemName
            ? 'border-b-4 border-primary data-[state=open]:border-primary'
            : ''
        }
      >
        {item.itemName}
      </NavigationMenuTrigger>

      <NavigationMenuContent className="absolute">
        <div className="w-screen">
          <div className="container flex h-[200px] items-center justify-center gap-0.5 px-[15px] py-4">
            {item.submenu[0].navLinks.map((nLink) => {
              return (
                <NavigationMenuContentLink
                  key={nLink.id}
                  asChild
                  className="relative block h-full w-full"
                >
                  <Link
                    to={`/${nLink.entry?.[0]?.uri}`}
                    prefetch="intent"
                    className="group/nav"
                  >
                    {nLink.hasBannerImage ? (
                      <Frame>
                        <div className="z-20 bg-gradient-nav-thumbnail" />
                        <Image
                          src={nLink.bannerImage?.[0]?.url!}
                          layout="constrained"
                          aspectRatio={3}
                          height={200}
                          priority={true}
                          className="z-10 duration-700 ease-linear group-hover/nav:scale-105"
                        />
                      </Frame>
                    ) : null}

                    <h3 className="absolute bottom-2 left-3.5 z-30 font-medium text-white">
                      {nLink.entry?.[0]?.slug === 'applications' ? 'All' : ''}{' '}
                      {nLink.entry?.[0]?.title}
                    </h3>
                  </Link>
                </NavigationMenuContentLink>
              );
            })}
          </div>
        </div>
      </NavigationMenuContent>
    </NavigationMenuItem>
  ) : null;
}

function NavLinkItem({ item }: { item: NavItem }) {
  const activeTrigger = useActiveTrigger();
  const { isDressed } = useNav();

  return !item.hasSubmenu ? (
    <NavigationMenuItem key={item.entry[0]?.id}>
      <NavigationMenuLink
        asChild
        className={
          activeTrigger === item.itemName
            ? 'border-b-4 border-primary hover:border-primary'
            : ''
        }
      >
        <Link to={`/${item.entry[0]?.uri}`} prefetch="intent">
          <BottomBorderClip
            position="left"
            borderColor={isDressed ? 'border-white' : 'border-transparent'}
          />
          <BottomBorderClip
            position="left"
            borderColor={isDressed ? 'border-white' : 'border-transparent'}
            className={cn(
              '-bottom-1 h-px border-b ',
              isDressed ? 'border-neutral-100' : '',
            )}
          />
          {item.entry[0]?.title}
          <BottomBorderClip
            position="right"
            borderColor={isDressed ? 'border-white' : 'border-transparent'}
          />
          <BottomBorderClip
            position="right"
            borderColor={isDressed ? 'border-white' : 'border-transparent'}
            className={cn(
              '-bottom-1 h-px border-b ',
              isDressed ? 'border-neutral-100' : '',
            )}
          />
        </Link>
      </NavigationMenuLink>
    </NavigationMenuItem>
  ) : null;
}

type Props = {
  isHomepage?: boolean;
  data: NavItemProps;
  notification: GeneralProps;
  envVars: EnvVarsType;
};

export default function TopBar(props: Props) {
  const { isHomepage, data, notification, envVars } = props;

  return (
    <div>
      {notification.enableNotificationBar ? (
        <SiteWideMessageBar>
          <div className="flex flex-col items-center justify-center gap-1.5 text-center lg:flex-row">
            <div
              dangerouslySetInnerHTML={{
                __html: notification.notificationMessage ?? '',
              }}
            />
            {notification?.linkUrl && notification?.linkUrl?.length > 0 ? (
              <a
                href={notification.linkUrl}
                className="text-xs uppercase underline underline-offset-2 hover:no-underline"
                target="_blank"
              >
                {notification?.linkLabel}
              </a>
            ) : null}
          </div>
        </SiteWideMessageBar>
      ) : null}

      <MotionBar
        isHomepage={isHomepage}
        className={`hidden lg:block ${notification.enableNotificationBar ? 'top-[85px] lg:top-[50px]' : ''}`}
      >
        <NavigationMenu>
          {/* <div
            className="container z-40 flex h-[66px] w-full flex-1 items-center justify-between px-[15px]"
            data-topbar-inner
          > */}
          <>
            <Link to="/" prefetch="intent">
              <img src={VPTLogo} alt="VPT Logo" width="101" height="31" />
            </Link>
            <NavigationMenuList>
              {data.nav.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <PageAndListsSubmenu item={item} />
                    <NavListsSubmenu item={item} />
                    <NavLinkItem item={item} />
                  </React.Fragment>
                );
              })}
            </NavigationMenuList>
            <Autocomplete
              id='autocomplete-desktop'
              key="autocomplete-desktop"
              placeholder="Search"
              detachedMediaQuery="none"
              openOnFocus={true}
              envVars={envVars}
              classNames={{
                form: 'relative flex bg-neutral-100',
                input: 'placeholder:text-muted-foreground',
              }}
            />
          </>
          {/* </div> */}
        </NavigationMenu>
      </MotionBar>

      <TopBarMobile {...props} />
    </div>
  );
}
