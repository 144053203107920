import { Input } from '#app/components/ui/input';

import { Container, ContainerContent } from '#app/components/layouts';

function NewsletterBar() {
  return (
    <Container className="relative z-30 box-border h-[190px] bg-gradient-primary lg:h-[105px]">
      <ContainerContent className="flex h-full flex-col items-center justify-center px-[15px] lg:flex-row lg:justify-between">
        <p className="mb-[15px] text-3xl font-medium text-white lg:mb-0">
          Get the Latest News & Product Updates
        </p>
        <form
          action={`https://analytics.clickdimensions.com/forms/h/aY5jAHEYn7kuuHw6ZSmdoi`}
          method="post"
          target="_blank"
        >
          <div className="flex h-[44px] w-full bg-white sm:w-[440px]">
            <Input
              placeholder="Email Address"
              className="text-gray-100 rent h-[44px] w-[calc(100%_-100px)] pr-8 focus-visible:ring-0 focus-visible:ring-offset-0 sm:w-[260px]"
              name="email"
              type="email"
              required
            />
            <button className="font-mediumx w-[100px] border border-neutral bg-primary text-sm tracking-[0.16px] text-white hover:bg-primary-600 sm:w-[180px]">
              Sign Up <span className="hidden sm:inline">For Newsletter</span>
            </button>
          </div>
        </form>
      </ContainerContent>
    </Container>
  );
}

export default NewsletterBar;
