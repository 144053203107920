import React from 'react';
import { Link } from '@remix-run/react';
import { CaretLeft } from '@phosphor-icons/react/dist/csr/CaretLeft';
import { CaretRight } from '@phosphor-icons/react/dist/csr/CaretRight';
import { X } from '@phosphor-icons/react/dist/csr/X';

import {
  MotionBar,
  MobileMainMenu,
  MobileSearchPanel,
  MobileAutocompleteButton,
} from '#app/components/ui/top-bar-navigation-menu';
import SearchBox from '#app/components/search-box';
import VPTLogo from '#app/assets/img/vpt-logo-blue.svg';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetOverlay,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
} from '#app/components/ui/sheet';
import { Stack } from '#app/components/layouts';
import { cn } from '#app/utils/misc';
import Autocomplete from '#app/components/algolia/autocomplete.js';

function PageAndSingleListSubmenu({
  item,
  title,
  setOpen,
}: {
  item: NavItem;
  title: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Sheet key="submenu">
      <SheetTrigger className="flex h-[44px] w-full items-center justify-between py-2 pl-[15px] pr-5 text-sm font-semibold">
        {item.itemName} <CaretRight size={14} />
      </SheetTrigger>
      <SheetContent className="w-full lg:hidden">
        <div className="flex h-[66px] justify-between border-b border-neutral-100 pl-[15px] pr-4">
          <SheetClose className="font-medium text-primary">
            <p className="flex items-center gap-x-1">
              <CaretLeft size={14} /> Main Menu
            </p>
          </SheetClose>
          <SheetClose>
            <X size={29} />
            <span className="sr-only">Close</span>
          </SheetClose>
        </div>
        <SheetTitle className="sr-only">{title}</SheetTitle>
        <SheetDescription className="sr-only">
          Choose the options for {title}
        </SheetDescription>

        {item.submenu[0]?.typeHandle === 'pageAndLists' ? (
          <Stack className="gap-y-[7px] pt-4">
            {item.submenu[0]?.entry?.length ? (
              <Link
                to={`/${item.submenu[0]?.entry?.[0]?.uri!}`}
                className="flex h-[44px] w-full items-center px-[15px] py-2 text-sm font-semibold"
                onClick={() => setOpen(false)}
              >
                {/* {item.submenu[0]?.entry?.[0]?.title} */}
                {item.submenu[0]?.navTitle}
              </Link>
            ) : null}

            {item.submenu[0].listOfLinks.map((li) => {
              return li.navLinks.map((navLink) => {
                if (!navLink.hideOnFooterOrMobile) {
                  return (
                    <NavLink
                      key={navLink.entry?.[0]?.id}
                      navLink={navLink}
                      setOpen={setOpen}
                    />
                  );
                }
              });
            })}
          </Stack>
        ) : null}
      </SheetContent>
    </Sheet>
  );
}

function NavListsSubmenu({
  item,
  title,
  setOpen,
}: {
  item: NavItem;
  title: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Sheet key="submenu">
      <SheetTrigger className="flex h-[44px] w-full items-center justify-between py-2 pl-[15px] pr-5 text-sm font-semibold">
        {item.itemName} <CaretRight size={14} />
      </SheetTrigger>
      <SheetContent className="w-full lg:hidden">
        <div className="flex h-[66px] justify-between border-b border-neutral-100 pl-[15px] pr-4">
          <SheetClose className="font-medium text-primary">
            <p className="flex items-center gap-x-1">
              <CaretLeft size={14} /> Main Menu
            </p>
          </SheetClose>
          <SheetClose>
            <X size={29} />
            <span className="sr-only">Close</span>
          </SheetClose>
        </div>
        <SheetTitle className="sr-only">{title}</SheetTitle>
        <SheetDescription className="sr-only">
          Choose the options for {title}
        </SheetDescription>

        {item.submenu[0]?.typeHandle === 'navLists' ? (
          <Stack className="gap-y-[7px] pt-4">
            {item.submenu[0].navLinks.map((navLink) => (
              <NavLink
                key={navLink.entry?.[0]?.id}
                navLink={navLink}
                setOpen={setOpen}
              />
            ))}
          </Stack>
        ) : null}
      </SheetContent>
    </Sheet>
  );
}

function NavLinkItem({ item }: { item: NavItem }) {
  return <NavLink key={item.entry?.[0]?.id} navLink={item} />;
}

function NavLink({
  navLink,
  setOpen,
}: {
  navLink: {
    id: string;
    entry?: OneItemOrEmptyArray<NavLink>;
  };
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Link
      to={`/${navLink.entry?.[0]?.uri}`}
      className="flex h-[44px] w-full items-center px-[15px] py-2 text-sm font-semibold"
      onClick={setOpen ? () => setOpen(false) : undefined}
      prefetch="intent"
    >
      {navLink.entry?.[0]?.title}
    </Link>
  );
}

function MainMenu({ data }: { data: NavItemProps }) {
  const [open, setOpen] = React.useState(false);

  return (
    <MobileMainMenu open={open} setOpen={setOpen}>
      <SheetPortal>
        <SheetOverlay />
        <SheetContent className="w-full lg:hidden">
          <div className="flex h-[66px] justify-end border-b border-neutral-100 pr-4">
            <SheetClose>
              <X size={29} />
              <span className="sr-only">Close</span>
            </SheetClose>
          </div>
          <SheetTitle className="sr-only">Main Menu</SheetTitle>
          <SheetDescription className="sr-only">
            Choose where you want to go
          </SheetDescription>
          <div className="gap-y-[7px] pt-4">
            {data.nav.map((item) => {
              switch (item.itemName) {
                // case 'Products':
                //   return (
                //     <div key={item.id}>
                //       {item.submenu[0]?.typeHandle === 'pageAndLists' ? (
                // <Link
                //   to={`/${item.submenu[0]?.entry?.[0]?.uri!}`}
                //   className="flex h-[44px] w-full items-center px-[15px] py-2 text-sm font-semibold"
                //   prefetch="intent"
                // >
                //   {item.itemName}
                // </Link>
                //       ) : null}
                //     </div>
                //   );
                case 'Company':
                case 'Products':
                case 'Quality':
                case 'Resources':
                  return (
                    <div key={item.id}>
                      {item.submenu[0]?.typeHandle === 'pageAndLists' &&
                      item.submenu[0].noSubmenuLinksOnMobile ? (
                        <Link
                          to={`/${item.submenu[0]?.entry?.[0]?.uri!}`}
                          className="flex h-[44px] w-full items-center px-[15px] py-2 text-sm font-semibold"
                          prefetch="intent"
                        >
                          {item.itemName}
                        </Link>
                      ) : (
                        <PageAndSingleListSubmenu
                          item={item}
                          title={`${item.itemName} Menu`}
                          setOpen={setOpen}
                        />
                      )}
                    </div>
                  );

                case 'Applications':
                  return (
                    <div key={item.id}>
                      <NavListsSubmenu
                        item={item}
                        title="Applications Menu"
                        setOpen={setOpen}
                      />
                    </div>
                  );

                // case 'Resources':
                //   return (
                //     <div key={item.id}>
                //       <PageAndSingleListSubmenu
                //         item={item}
                //         title="Resources Menu"
                //         setOpen={setOpen}
                //       />
                //     </div>
                //   );

                // case 'Quality':
                //   return (
                //     <div key={item.id}>
                //       <PageAndSingleListSubmenu
                //         item={item}
                //         title="Quality Menu"
                //         setOpen={setOpen}
                //       />
                //     </div>
                //   );

                // case 'Company':
                //   return (
                //     <div key={item.id}>
                //       <PageAndSingleListSubmenu
                //         item={item}
                //         title="Company Menu"
                //         setOpen={setOpen}
                //       />
                //     </div>
                //   );

                default:
                  return (
                    <div key={item.id}>
                      <NavLinkItem item={item} />
                    </div>
                  );
              }
            })}
          </div>
        </SheetContent>
      </SheetPortal>
    </MobileMainMenu>
  );
}

// function SearchPanel() {
//   return (
//     <MobileSearchPanel>
//       <SheetPortal>
//         <SheetOverlay />
//         <SheetContent className="w-full p-0 lg:hidden">
//           <div className="flex h-[66px] w-full items-center border-b border-neutral-100 pl-[15px] pr-5">
//             <Autocomplete
//               key='autocomplete-mobile'
//               openOnFocus={true}
//               envVars={envVars}
//             />
//             {/* <SearchBox
//               actionUrl="/search/products"
//               className="w-[calc(100vw_-90px)] sm:w-[294px]"
//             /> */}
//             <SheetClose className="ml-[15px]">
//               <span className="text-sm font-medium">Cancel</span>
//             </SheetClose>
//           </div>
//           <SheetHeader>
//             <SheetTitle className="sr-only">Search Panel</SheetTitle>
//             <SheetDescription className="sr-only">
//               Type in the search box to find out what you're looking for
//             </SheetDescription>
//           </SheetHeader>
//         </SheetContent>
//       </SheetPortal>
//     </MobileSearchPanel>
//   );
// }

type Props = {
  isHomepage?: boolean;
  data: NavItemProps;
  notification: GeneralProps;
  envVars: EnvVarsType;
};

function TopBarMobile({
  isHomepage = false,
  data,
  notification,
  envVars,
}: Props) {
  return (
    <MotionBar
      isHomepage={isHomepage}
      className={cn(
        'mobilexxx fixed z-50 block h-[66px] w-full lg:hidden',
        notification.enableNotificationBar ? 'top-[85px] lg:top-[50px]' : '',
        isHomepage ? '' : 'border-b border-neutral-100 bg-white',
      )}
    >
      <div className="flex h-full items-center justify-between px-[15px] md:px-[30px]">
        <Link to="/" prefetch="intent">
          <img src={VPTLogo} alt="VPT Logo" width="101" height="31" />
        </Link>

        <div className="flex gap-x-[10px]">
          <MobileAutocompleteButton envVars={envVars} />
          <MainMenu data={data} />
        </div>
      </div>
    </MotionBar>
  );
}

export default TopBarMobile;
