import * as React from 'react';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { motion, useScroll, useMotionValueEvent } from 'framer-motion';
import { cva } from 'class-variance-authority';
import { List } from '@phosphor-icons/react/dist/csr/List';
import { MagnifyingGlass } from '@phosphor-icons/react/dist/csr/MagnifyingGlass';

import { Sheet, SheetTrigger } from '#app/components/ui/sheet';
import { cn } from '#app/utils/misc.tsx';
import Autocomplete from '#app/components/algolia/autocomplete.js';

const BottomBorderClip = ({
  position,
  borderColor,
  className,
}: {
  position: 'left' | 'right';
  borderColor?: 'border-white' | 'border-transparent';
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'absolute -bottom-[3px] w-[15px] border-b-4 ',
        position === 'left' ? 'left-0' : 'right-0',
        borderColor,
        className,
        // borderColor === 'border-white' ? '-bottom-[3px]' : '',
      )}
    />
  );
};

const SiteWideMessageBar = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="bar"
      className={cn(
        'fixed z-50 flex h-[85px] w-full items-center justify-center bg-gradient-primary p-[15px] text-sm text-white lg:h-[50px]',
        className,
      )}
      {...props}
    />
  );
});
SiteWideMessageBar.displayName = 'SiteWideMessageBar';

const MotionBar = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { isHomepage?: boolean }
>(({ className, children, isHomepage }, ref) => {
  const { scrollY } = useScroll();
  const [isDressed, setDressed] = React.useState(false);
  const [currentY, setCurrentY] = React.useState(0);

  useMotionValueEvent(scrollY, 'change', (current) => {
    setCurrentY(current);
    if (current > 150) {
      setDressed(true);
    } else {
      setDressed(false);
    }
  });

  const handleMouseOut = () => {
    if (currentY > 150) {
      setDressed(true);
    } else {
      setDressed(false);
    }
  };

  if (isHomepage) {
    return (
      <motion.div
        ref={ref}
        variants={{
          dressed: {
            backgroundColor: ['#ffffff'],
          },
          stripped: { backgroundColor: ['transparent'] },
        }}
        onMouseOver={() => setDressed(true)}
        onMouseOut={handleMouseOut}
        animate={isDressed ? 'dressed' : 'stripped'}
        transition={{ duration: 0.35, ease: 'easeInOut' }}
        className={cn(
          'fixed z-50 h-[66px] w-full',
          isDressed ? 'shadow-xsx border-b border-neutral-100' : '',
          className,
        )}
      >
        <NavContext.Provider
          value={{ isDressed, setDressed, handleMouseOut, isHomepage }}
        >
          {children}
        </NavContext.Provider>
      </motion.div>
    );
  }

  return (
    <div
      className={cn(
        'shadow-xsx fixed z-50 h-[66px] w-full border-b border-neutral-100 bg-white',
        className,
      )}
    >
      <NavContext.Provider
        value={{
          isDressed: true,
          setDressed,
          isHomepage: false,
          handleMouseOut,
        }}
      >
        {children}
      </NavContext.Provider>
    </div>
  );
});

MotionBar.displayName = 'MotionBar';

type NavContextProps = {
  isDressed: boolean;
  setDressed: React.Dispatch<React.SetStateAction<boolean>>;
  isHomepage: boolean;
  handleMouseOut: () => void;
};

const NavContext = React.createContext<NavContextProps | null>(null);

function useNav() {
  const context = React.useContext(NavContext);

  if (!context) {
    throw new Error('useNav must be used within a <NavigationMenu />');
  }

  return context;
}

const NavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { setDressed, handleMouseOut } = useNav();

  const handleValueChange = (val: string) => {
    if (val) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      // handleMouseOut();
    }
  };

  return (
    <NavigationMenuPrimitive.Root
      ref={ref}
      defaultValue=""
      onMouseOver={() => setDressed(true)}
      onMouseOut={handleMouseOut}
      onValueChange={(val) => handleValueChange(val)}
      className={cn('relative z-40 w-full', className)}
      {...props}
    >
      <div
        className="container z-40 flex h-[66px] w-full flex-1 items-center justify-between px-[15px]"
        data-topbar-inner
      >
        {children}
      </div>
      {isOpen && (
        <div className="absolute left-0 top-[66px] z-30 h-screen w-screen bg-black/70" />
      )}
    </NavigationMenuPrimitive.Root>
  );
});
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => {
  return (
    <NavigationMenuPrimitive.List
      ref={ref}
      className={cn(
        'flex-1x space-x-[30px]x group flex list-none items-center justify-between',
        className,
      )}
      {...props}
    />
  );
});
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

const NavigationMenuItem = NavigationMenuPrimitive.Item;

const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => {
  const { isDressed } = useNav();

  const triggerStyle = cva(
    cn(
      'group inline-flex h-[66px] w-max items-center justify-center border-b-4 border-transparent px-[15px] py-2 transition-colors hover:border-b-4 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:border-b-4 data-[state=open]:border-b-4 data-[state=open]:border-neutral-300 data-[active]:border-primaryx relative -bottom-pxx bottom-0 text-base font-semibold',
      isDressed ? 'hover:text-accent-foreground text-gray' : ' text-white',
    ),
  );

  return (
    <NavigationMenuPrimitive.Trigger
      ref={ref}
      className={cn(triggerStyle(), 'group', className)}
      {...props}
    >
      <BottomBorderClip
        position="left"
        borderColor={isDressed ? 'border-white' : 'border-transparent'}
      />
      <BottomBorderClip
        position="left"
        borderColor={isDressed ? 'border-white' : 'border-transparent'}
        className={cn(
          '-bottom-1 h-px border-b ',
          isDressed ? 'border-neutral-100' : '',
        )}
      />
      {children}
      <BottomBorderClip
        position="right"
        borderColor={isDressed ? 'border-white' : 'border-transparent'}
      />
      <BottomBorderClip
        position="right"
        borderColor={isDressed ? 'border-white' : 'border-transparent'}
        className={cn(
          '-bottom-1 h-px border-b ',
          isDressed ? 'border-neutral-100' : '',
        )}
      />
    </NavigationMenuPrimitive.Trigger>
  );
});
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, children, ...props }, ref) => {
  const { setDressed } = useNav();
  return (
    <NavigationMenuPrimitive.Content
      ref={ref}
      className={cn(
        'nav-content top-fullx bg-neutralx data-[motion=from-end]:slide-in-from-right-52x data-[motion=from-start]:slide-in-from-left-52x data-[motion=to-end]:slide-out-to-right-52x data-[motion=to-start]:slide-out-to-left-52x data-[motion^=from-]:animate-inx data-[motion^=to-]:animate-outx data-[motion^=from-]:fade-inx data-[motion^=to-]:fade-outx left-0 top-[66px] z-40 w-full md:absolute md:w-auto',
        className,
      )}
      // forceMount
      {...props}
    >
      <div className="relative z-40 bg-neutral">{children}</div>
    </NavigationMenuPrimitive.Content>
  );
});
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;

const NavigationMenuLink = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link>
>(({ className, children, ...props }, ref) => {
  const { isDressed } = useNav();

  const linkStyle = cva(
    cn(
      'group inline-flex h-[66px] w-max items-center justify-center border-b-4 border-transparent px-[15px] py-2 transition-colors hover:border-neutral-300 hover:border-b-4 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:border-b-4 data-[active]:border-primary data-[state=open]:border-b-4 data-[state=open]:border-primary relative bottom-0 text-base font-semibold',
      isDressed ? 'hover:text-accent-foreground text-gray' : 'text-white',
    ),
  );

  return (
    <NavigationMenuPrimitive.Link
      className={cn(linkStyle(), 'group', className)}
      {...props}
    >
      {children}
    </NavigationMenuPrimitive.Link>
  );
});

NavigationMenuLink.displayName = NavigationMenuPrimitive.Link.displayName;

const NavigationMenuContentLink = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link>
>(({ className, children, ...props }, ref) => {
  const linkStyle = cva(cn('text-sm leading-7 text-gray hover:underline'));

  return (
    <NavigationMenuPrimitive.Link
      className={cn(linkStyle(), 'group', className)}
      {...props}
    >
      {children}
    </NavigationMenuPrimitive.Link>
  );
});

NavigationMenuContentLink.displayName =
  NavigationMenuPrimitive.Link.displayName;

function MobileMainMenu({
  open,
  setOpen,
  children,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}) {
  const { isDressed, isHomepage } = useNav();

  return (
    <Sheet key="mainMenu" open={open} onOpenChange={setOpen}>
      <SheetTrigger>
        <List
          size={28}
          color={isHomepage && !isDressed ? '#ffffff' : '#0A1928'}
        />
      </SheetTrigger>
      {children}
    </Sheet>
  );
}

function MobileSearchPanel({ children }: { children: React.ReactNode }) {
  const { isDressed, isHomepage } = useNav();
  return (
    <Sheet>
      <SheetTrigger>
        <MagnifyingGlass
          size={24}
          color={isHomepage && !isDressed ? '#ffffff' : '#0A1928'}
        />
      </SheetTrigger>
      {children}
    </Sheet>
  );
}

function MobileAutocompleteButton({ envVars }: { envVars: EnvVarsType }) {
  return (
    <Autocomplete
      id='autocomplete-mobile'
      key="autocomplete-mobile"
      placeholder="Search"
      detachedMediaQuery=""
      openOnFocus={true}
      envVars={envVars}
      classNames={{
        form: 'relative flex bg-neutral-100',
        detachedOverlay: 'aa-DetachedOverlay--dark fixed inset-0',
        detachedFormContainer: 'custom-autocomplete'
      }}
    />
  );
}

export {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuContent,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuContentLink,
  SiteWideMessageBar,
  MotionBar,
  MobileMainMenu,
  MobileSearchPanel,
  MobileAutocompleteButton,
  BottomBorderClip,
  useNav,
};
