import {
  type AutocompleteReshapeSource,
  type BaseItem,
} from '@algolia/autocomplete-core';
import { flatten } from '@algolia/autocomplete-shared';

type createFillWithOptions = {
  mainSourceId: string;
  limit: number;
};

type AutocompleteReshapeFunction<TParams = unknown> = <TItem extends BaseItem>(
  ...params: TParams[]
) => (
  ...expressions: Array<AutocompleteReshapeSource<TItem>>
) => Array<AutocompleteReshapeSource<TItem>>;

// This reshape function computes the total number of source items and
// limits the provided main source number of items until it reaches the provided limit.
export const createFillWith: AutocompleteReshapeFunction<
  createFillWithOptions
> = ({ mainSourceId, limit }) => {
  return function runUniqBy(...rawSources) {
    const originalSources = normalizeReshapeSources(rawSources);
    const otherSources = originalSources.filter(
      (s) => s.sourceId !== mainSourceId,
    );

    // Compute the total number of items per source.
    let totalItemNb = 0;
    otherSources.forEach((source) => {
      totalItemNb += source.getItems().length;
    });

    return originalSources.map((source) => {
      let transformedSource = source;

      // Limit the main source items length based on the provided limit and
      // the computed total number of items.
      if (source.sourceId === mainSourceId) {
        transformedSource = {
          ...source,
          getItems() {
            return source.getItems().slice(0, Math.max(limit - totalItemNb, 0));
          },
        };
      }

      return transformedSource;
    });
  };
};

// We filter out falsy values because dynamic sources may not exist at every render.
// We flatten to support pipe operators from functional libraries like Ramda.
export function normalizeReshapeSources<TItem extends BaseItem>(
  sources: Array<AutocompleteReshapeSource<TItem>>,
) {
  return flatten(sources).filter(Boolean);
}

type UniqByPredicate<TItem extends BaseItem> = (params: {
  source: AutocompleteReshapeSource<TItem>;
  item: TItem;
}) => TItem;

export const uniqBy: AutocompleteReshapeFunction<UniqByPredicate<any>> = <
  TItem extends BaseItem,
>(
  predicate: UniqByPredicate<any>,
) => {
  return function runUniqBy(...rawSources) {
    const sources = normalizeReshapeSources(rawSources);
    const seen = new Set<TItem>();

    return sources.map((source) => {
      const items = source.getItems().filter((item) => {
        const appliedItem = predicate({ source, item });
        const hasSeen = seen.has(appliedItem);

        seen.add(appliedItem);

        return !hasSeen;
      });

      return {
        ...source,
        getItems() {
          return items;
        },
      };
    });
  };
};
